import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import { useProductOrder } from 'components/product-order/Provider';
import { OrderInformation } from '../lib/ProductSummary';
import Heading from 'libs/heading';
import AuthLogOut from 'components/authentication/AuthLogOut';
import Spacing from 'layouts/Spacing';
import MediumWidth from 'layouts/medium-width';

const Wrap = styled.div`
	scroll-margin: 50px;
	overflow: hidden;
	${p =>
		p.theme.media.medium(css`
			scroll-margin: 100px;
		`)}
`;

const Intro = styled.div`
	text-align: center;
	width: 100%;
	margin: 60px 0 40px;
	color: white;
	padding: 0 ${p => p.theme.gutters.xsmall};
	${p =>
		p.theme.media.mediumDown(css`
			margin: 40px 0 20px;
		`)};
`;

const Title = styled(Heading)`
	margin-bottom: 20px;
	${p =>
		p.theme.media.smallOnly(css`
			margin: 10px 0;
		`)}
`;

const SubTitle = styled(Heading)`
	margin: 0 0 30px;
	font-weight: 400 !important;
`;

const Summary = styled(MediumWidth)`
	margin: 40px auto 0;
`;

const Box = styled.div`
	background: ${p => p.theme.colors.white};
	border-radius: ${p => p.theme.utils.borderRadius};
	padding: 45px;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 30px;
		`)};
`;

export default function ThankYou() {
	const { orderData, product, trackStep, storeBeforeLogout, clearStorage } =
		useProductOrder();

	const [receiptData, setReceiptData] = useState({});

	useEffect(() => {
		if (Object.keys(orderData).length === 0) return;

		// Copy the data to local state
		setReceiptData(orderData);

		// Store the data before logout
		storeBeforeLogout();

		// 2 sec delay before clearing the storage
		setTimeout(() => {
			clearStorage();
		}, 2000);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!orderData]);

	// Track step to Segment
	useEffect(() => {
		trackStep(orderData?.step);
		//eslint-disable-next-line
	}, []);

	return (
		<Wrap data-step="section-5" data-cy="product-order-step-5">
			<Spacing spacing={{ top: 'large', bottom: 'xlarge' }}>
				<Intro id="order-form-intro">
					<Title level="h1">Tusen takk for din bestilling!</Title>
					<SubTitle level="h2" className="h4">
						Vi har mottatt din bestilling og vi tar kontakt med deg
						for videre avtale.
					</SubTitle>
				</Intro>
				<Summary>
					<Box>
						<OrderInformation
							orderData={receiptData}
							product={product}
							lastStep={true}
						/>
						<AuthLogOut
							centered={false}
							onClick={() => {
								clearStorage();

								// Redirect to main page
								navigate('/elektriker');
							}}
						/>
					</Box>
				</Summary>
			</Spacing>
		</Wrap>
	);
}
