import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import {
	updateStoredElektroOrder,
	useProductOrder,
} from 'components/product-order/Provider';
import UserInfo from 'components/product-order/lib/UserInfo';
import FormSubmit from 'components/product-order/steps/FormSubmit';
import FileLink from 'libs/FileLink';

const TermsPowerProduct = styled.div`
	padding: 0px 0 15px 5px;
	width: 100%;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 10px 0 20px 5px;
			margin-top: 5px;
		`)}
	ul {
		text-align: left;
		margin-bottom: 0 !important;
		font-size: 16px;
		line-height: 26px;
		padding-top: 0 !important;
		li {
			${p =>
				p.theme.media.smallOnly(css`
					margin-bottom: 5px;
				`)}
			&::before {
				background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'%3E%3Cpath fill='%230079AD' d='M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z'%3E%3C/path%3E%3C/svg%3E");
				top: 2px;
			}
		}
	}
	p {
		font-size: 16px;
		line-height: 26px;
		margin: 0;
		text-align: left;
		${p =>
			p.theme.media.smallOnly(css`
				font-size: 14px;
				line-height: 24px;
				margin: 0 !important;
			`)}
	}
`;

const TermsInformation = styled.div`
	margin: ${p => (p.lastStep ? '0' : '20px 0 0')};
	font-size: 17px;
	line-height: 29px;
	border-top: 1px solid ${p => p.theme.colors.grey300};
	padding: 25px 0 0;
	${p =>
		p.theme.media.mediumDown(css`
			margin: ${p => (p.lastStep ? '10px 0 0' : '0 0 30px')};
			padding: 20px 0 0;
			border-top: ${p => `1px solid ${p.theme.colors.grey300}`};
		`)};
	${TermsPowerProduct} {
		border-top: 0;
		margin-top: 0;
		padding-left: 0;
	}
	.terms {
		margin-bottom: 10px !important;
	}

	${p =>
		p.step === 4 &&
		p.theme.media.mediumDown(css`
			display: none;
		`)}
`;

const TermsTitle = styled.b`
	font-weight: 500;
	display: block;
	margin-bottom: 10px;
`;

const Wrapper = styled.div`
	${props =>
		props.theme.media.smallOnly(css`
			padding: 0;
			h2 {
				text-align: center;
			}
		`)}
	.form-footer {
		display: block;
		${props =>
			props.theme.media.large(css`
				display: none;
			`)}
	}
`;

const Summary = styled.div`
	form {
		margin-bottom: 40px;
	}
`;

export default function Confirm() {
	const { isMobile, orderData, trackStep } = useProductOrder();

	// Track step to Segment
	useEffect(() => {
		updateStoredElektroOrder({ ...orderData, step: orderData?.step });
		trackStep(4);
		//eslint-disable-next-line
	}, []);

	return (
		<Wrapper>
			<Summary>
				<UserInfo orderData={orderData} />
				<Terms />
			</Summary>

			{isMobile && <FormSubmit />}
		</Wrapper>
	);
}

export function Terms({ lastStep }) {
	const { orderData, step } = useProductOrder();
	if (!orderData?.product?.terms) return;

	return (
		<TermsInformation lastStep={lastStep} step={step}>
			<TermsTitle>Kjøpsbetingelser</TermsTitle>
			<FileLink
				href={orderData?.product?.terms}
				aria-label={`Se kjøpsbetingelser for salg og montering av varer fra nte.no til forbrukere`}
				target="_blank"
				text={`Se kjøpsbetingelser for salg og montering av varer fra nte.no til forbrukere`}
				showtype="false"
			/>
		</TermsInformation>
	);
}
