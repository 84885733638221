import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
	updateStoredElektroOrder,
	useProductOrder,
} from 'components/product-order/Provider';
import { AnchorLink } from 'components/Link';
import Fieldset, { Field, FieldTitle, Options } from './Form';
import Box from 'components/forms/Box';
import Radio from 'components/forms/Radio';
import ProductColumns from 'parts/product-columns/ProductColumns';
import IconWarning from 'images/icons/Warning';

const Warning = styled.div`
	display: flex;
	p {
		width: calc(100% - 25px);
		padding-left: 15px;
		margin: 0;
		span {
			display: block;
			&:not(:first-of-type) {
				margin-top: 20px;
			}
		}
	}
	svg {
		display: block;
		height: 24px;
		width: 25px;
		padding-left: 5px;
		color: ${p => p.theme.colors.blue600};
	}
`;

export default function PowerProductSelect() {
	const { spotpris, location, orderData, setOrderData, storedPowerOrder } =
		useProductOrder();

	useEffect(() => {
		updateStoredElektroOrder(orderData);

		//eslint-disable-next-line
	}, [orderData?.power?.product]);

	return (
		<div>
			<Box smallpadding="true">
				<Warning>
					<IconWarning />
					<p>
						<span>
							Månedlig beløp vil bli fakturert på din
							strømfaktura. Dette krever derfor at du er
							strømkunde av NTE. Sier du opp strømavtalen din før
							de 36 månedene har gått, vil du motta en faktura på
							restbeløpet. Ved å velge avbetaling samtykker du til
							at det blir gjennomført en kredittsjekk.
						</span>{' '}
						<span>
							Har du spørsmål ang. avbetaling kan du kontakte oss
							på{' '}
							<AnchorLink
								href="mailto:strom.kundeservice@nte.no"
								title="Send en e-post til strom.kundeservice@nte.no">
								strom.kundeservice@nte.no
							</AnchorLink>
							.
						</span>
					</p>
				</Warning>
			</Box>

			<Fieldset legend="Avbetaling">
				<Field style={{ marginTop: '10px' }}>
					<FieldTitle required>Velg strømavtale</FieldTitle>

					<Options>
						<Radio
							key="Jeg har allerede strømavtale fra NTE"
							id="Jeg har allerede strømavtale fra NTE"
							defaultValue="Jeg har allerede strømavtale fra NTE"
							label="Jeg har allerede strømavtale fra NTE"
							data-cy="powerProduct"
							checked={
								orderData?.power?.product ===
								'Jeg har allerede strømavtale fra NTE'
							}
							name="powerProduct"
							onChange={e => {
								if (e.target.checked) {
									e.target.setAttribute('checked', true);
								} else {
									e.target.removeAttribute('checked');
								}

								setOrderData(prev => ({
									...prev,
									power: {
										...prev.power,
										product:
											'Jeg har allerede strømavtale fra NTE',
										revenue: undefined,
									},
								}));
							}}
							required
						/>
						<Radio
							key="Spotpris"
							id="Spotpris"
							defaultValue="Spotpris"
							label="Jeg vil bestille Spotpris"
							data-cy="powerProduct"
							checked={orderData?.power?.product === 'Spotpris'}
							name="powerProduct"
							onChange={e => {
								if (e.target.checked) {
									e.target.setAttribute('checked', true);
								} else {
									e.target.removeAttribute('checked');
								}
								setOrderData(prev => ({
									...prev,
									power: {
										...prev.power,
										product: 'Spotpris',
										orderID:
											storedPowerOrder?.orderID ||
											undefined,
										revenue: spotpris?.revenue,
									},
									stepsValidated: !(
										storedPowerOrder?.orderID &&
										storedPowerOrder?.meterIds
									)
										? [1, 2]
										: [1, 2, 3],
								}));
							}}
							required
						/>
					</Options>

					{orderData?.power?.product === 'Spotpris' && (
						<>
							<p>
								Er du ikke kunde av NTE, må du bestille
								strømavtale først. Dette tar kun noen minutter
								og du blir automatisk sendt tilbake hit etter
								fullført bestilling. Klikk på knappen «Bestill
								Spotpris».
							</p>
							<ProductColumns
								perrow={1}
								componentindex={1}
								settings={['Skjul tittel og intro']}
								spacing={{ bottom: 'none' }}
								location={location}
								products={[
									{
										...spotpris,
										internal: {
											type: 'ContentfulInnholdStromprodukt',
										},
										readMore: false,
										onClick: e => {
											e?.preventDefault();
											setOrderData(prev => ({
												...prev,
												power: {
													...prev.power,
													product: 'Spotpris',
													revenue: spotpris?.revenue,
												},
											}));
										},
									},
								]}
								customButton={{
									text: `Bestill ${spotpris?.name}`,
									slug: `?produkt=elektro-spotpris&salgskanal=${encodeURI(
										'Elektro/WEB'
									)}`,
								}}
							/>
						</>
					)}
				</Field>
			</Fieldset>
		</div>
	);
}
