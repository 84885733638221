import React from 'react';
import styled, { css } from 'styled-components';

const FieldsetWrap = styled.fieldset`
	&:not(:first-of-type) {
		margin-top: 25px;
		padding-top: 25px;
		border-top: 1px solid ${p => p.theme.colors.grey300};
		${p =>
			p.theme.media.smallOnly(css`
				margin-top: 20px;
				padding-top: 20px;
			`)}
	}
`;
const LegendWrap = styled.legend`
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	font-weight: 500;
	margin: 0 0 12px;
	color: ${p => p.theme.colors.black};
`;

const FieldWrap = styled.div`
	padding: 7.5px 0;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 0 0 7.5px;
		`)}
	> div {
		width: 100%;
	}
	.terms {
		padding-top: 0;
		margin-bottom: 20px !important;
	}
	.component__product-columns {
		padding: 0;
		margin-bottom: 20px;
	}
`;

const ColumnWrap = styled.div`
	${p =>
		p.$disable &&
		css`
			cursor: no-drop;
			pointer-events: none;
		`};
	${p =>
		p.$flex &&
		css`
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			${p =>
				p.theme.media.medium(css`
					width: auto !important;
				`)};
		`};
	${p =>
		!p.$flex &&
		css`
			${p =>
				p.theme.media.medium(css`
					width: 50% !important;
				`)};
		`};
	${p =>
		p.theme.media.medium(css`
			&:nth-child(even) {
				padding-left: 7.5px;
			}
			&:nth-child(odd) {
				padding-right: 7.5px;
			}
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			&:first-of-type:not(:last-of-type) {
				margin-bottom: 7.5px;
			}
		`)}
`;

const FieldTitleWrap = styled.div`
	font-weight: 500;
	margin-bottom: 20px;
	.required-indicator {
		color: ${p => p.theme.colors.coral900};
		margin-left: 5px;
	}
`;

const InfoWrap = styled.p`
	margin: -10px 0 20px;
	width: 100%;
	em {
		font-style: normal;
		font-weight: 600;
		color: ${p => p.theme.colors.coral900};
	}
	svg {
		vertical-align: middle;
		fill: ${p =>
			p.$error ? p.theme.colors.coral900 : p.theme.colors.green600};
		margin-right: 10px;
		width: 20px;
		height: 20px;
	}
`;

const OptionGroup = styled.div`
	${p =>
		p.placement === 'horizontal' &&
		css`
			display: flex;
			align-items: center;
			justify-content: flex-start;
			> label {
				width: max-content;
				&:not(last-of-type) {
					padding-right: 60px;
				}
			}
		`};
`;

export default function Fieldset({ legend, hideLegend = true, children }) {
	if (!children) return <></>;
	return (
		<FieldsetWrap>
			{legend && (
				<LegendWrap
					{...(hideLegend && { className: 'screen-reader-text' })}>
					{legend}
				</LegendWrap>
			)}
			<div>{children}</div>
		</FieldsetWrap>
	);
}

export function Field({ width, children, style }) {
	if (!children) return <></>;
	return (
		<FieldWrap width={width} style={style}>
			{children}
		</FieldWrap>
	);
}

export function FieldTitle({ width, children, style, required = false }) {
	if (!children) return <></>;
	return (
		<FieldTitleWrap width={width} style={style}>
			{children}
			{required && <span className="required-indicator">*</span>}
		</FieldTitleWrap>
	);
}

export function Column({ style, children, flex = false, disable }) {
	if (!children) return <></>;
	return (
		<ColumnWrap $flex={flex} style={style} $disable={disable}>
			{children}
		</ColumnWrap>
	);
}

export function Info({ style, children, error = false, ...props }) {
	if (!children) return <></>;
	return (
		<InfoWrap style={style} $error={error} {...props}>
			{children}
		</InfoWrap>
	);
}

export function Options({ placement = 'vertical', children, ...props }) {
	if (!children) return <></>;
	return (
		<OptionGroup placement={placement} {...props}>
			{children}
		</OptionGroup>
	);
}
