import React from 'react';
import styled, { css } from 'styled-components';

import { transparentize } from 'polished';
import { formatPhoneNumber } from 'libs/content';

const Wrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	text-align: left;
	margin-bottom: ${p => (p.lastStep ? '20px' : '10px')};
	margin-top: ${p => (p.lastStep ? '10px' : 0)};
	${p =>
		p.theme.media.medium(css`
			margin-bottom: ${p => (p.lastStep ? '25px' : '30px')};
			margin-top: ${p => (p.lastStep ? '25px' : 0)};
		`)};
	${p =>
		p.lastStep &&
		css`
			${p =>
				p.theme.media.medium(css`
					padding-bottom: 25px;
					border-bottom: 1px solid ${p => p.theme.colors.grey300};
				`)};
			b {
				color: ${p => transparentize(0.3, p.theme.colors.black)};
				font-size: 14px;
				line-height: 24px;
				text-align: left;
				font-weight: 400;
				margin: 0 0 5px;
			}
		`}
`;

const Left = styled.div`
	width: 100%;
	${p =>
		p.theme.media.medium(css`
			padding: 0 20px 0 0;
			width: 55%;
			border-right: 1px solid ${p => p.theme.colors.grey300};
		`)}
`;

const Right = styled.div`
	width: 100%;
	${p =>
		p.theme.media.medium(css`
			padding: 0 0 0 40px;
			width: 45%;
		`)}
`;

const Entry = styled.div`
	&:not(:last-of-type) {
		margin-bottom: 10px;
		${p =>
			p.theme.media.medium(css`
				margin-bottom: 20px;
			`)}
	}
	${p =>
		p.theme.media.smallOnly(css`
			margin-bottom: 10px;
			border-bottom: 1px solid ${p => p.theme.colors.grey300};
			padding-bottom: 10px;
		`)}
	b,
	span {
		display: block;
		small {
			display: block;
		}
	}
`;

export default function UserInfo({ lastStep = false, orderData }) {
	return (
		<Wrap lastStep={lastStep}>
			<Left>
				<Entry>
					<b>Kontaktinfo</b>
					{orderData?.firstname && orderData?.lastname && (
						<span>{`${orderData?.firstname} ${orderData?.lastname}`}</span>
					)}
					{orderData?.email && (
						<span>E-post: {orderData?.email}</span>
					)}
					{orderData?.phone && (
						<span>Tlf: {formatPhoneNumber(orderData?.phone)}</span>
					)}
					{orderData?.birthdate && (
						<span>Fødselsdato: {orderData?.birthdate}</span>
					)}
				</Entry>
				<Entry>
					<b>Adresse</b>
					{orderData?.address?.full && (
						<span>{orderData?.address?.full}</span>
					)}
					{orderData?.address?.zip && (
						<span>{orderData?.address?.zip}</span>
					)}
					{orderData?.address?.city && (
						<span>{orderData?.address?.city}</span>
					)}
				</Entry>

				<Entry>
					<b>Leveringsmetode</b>
					<span>{orderData?.deliveryMethod}</span>
				</Entry>
			</Left>

			<Right>
				<Entry>
					<b>Betalingsmåte</b>
					{orderData?.paymentMethod && (
						<span>
							{orderData?.paymentMethod === 'downpayment' ? (
								<>
									Avbetaling på{' '}
									{orderData?.power?.downpaymentMonths}
								</>
							) : orderData?.paymentMethod === 'invoice' ? (
								<>Faktura</>
							) : null}
						</span>
					)}

					{orderData?.paymentMethod === 'downpayment' && (
						<>
							{orderData?.power?.product && (
								<span style={{ margin: '20px 0' }}>
									Strømavtale: {orderData?.power?.product}
									{orderData?.power?.orderID && (
										<small>
											{`Ordrenr: ${orderData?.power?.orderID}`}
										</small>
									)}
								</span>
							)}
							{orderData?.power?.meterIds && (
								<span>
									Målepunkt-ID: {orderData?.power?.meterIds}
								</span>
							)}
						</>
					)}
				</Entry>

				<Entry>
					<b>Fakturaadresse</b>
					{orderData?.invoiceAddress?.full && (
						<span>{orderData?.invoiceAddress?.full}</span>
					)}
					{orderData?.invoiceAddress?.zip && (
						<span>{orderData?.invoiceAddress?.zip}</span>
					)}
					{orderData?.invoiceAddress?.city && (
						<span>{orderData?.invoiceAddress?.city}</span>
					)}
				</Entry>
				{lastStep && (
					<Entry>
						<b>Godtatt elektronisk markedsføring</b>
						<span>{orderData?.acceptMarketing ? 'Ja' : 'Nei'}</span>
					</Entry>
				)}
			</Right>
		</Wrap>
	);
}
