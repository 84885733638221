import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { gql, useApolloClient } from '@apollo/client';

import { useProductOrder } from 'components/product-order/Provider';
import { useAnalytics } from 'context/AnalyticsProvider';
import { AnchorLink } from 'components/Link';
import useSaveConsent, {
	ConsentCheckbox,
	PrivacyPolicyCheckbox,
} from 'components/consent-portal/useSaveConsent';
import Button from 'components/forms/Button';
import Error from 'components/error/Error';

export const ORDER_PRODUCT = gql`
	mutation orderProduct(
		$orderId: String
		$phone: String!
		$email: String!
		$firstname: String!
		$lastname: String!
		$address: AddressInput!
		$invoiceAddress: AddressInput
		$deliveryMethod: String
		$birthdate: String!
		$product: ProductInput!
		$power: PowerInput
		$department: DepartmentInput
		$paymentMethod: String!
		$priceTotal: Int!
		$priceTotalWithDiscount: Int
		$pricePerMonth: String
		$discount: DiscountInput
		$acceptMarketing: Boolean!
		$orderRecipient: String
		$revenue: Int
	) {
		success: orderProduct(
			orderId: $orderId
			phone: $phone
			email: $email
			firstname: $firstname
			lastname: $lastname
			address: $address
			invoiceAddress: $invoiceAddress
			deliveryMethod: $deliveryMethod
			birthdate: $birthdate
			product: $product
			power: $power
			department: $department
			paymentMethod: $paymentMethod
			priceTotal: $priceTotal
			priceTotalWithDiscount: $priceTotalWithDiscount
			pricePerMonth: $pricePerMonth
			discount: $discount
			acceptMarketing: $acceptMarketing
			orderRecipient: $orderRecipient
			revenue: $revenue
		)
	}
`;

const Footer = styled.div`
	display: block;
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid ${p => p.theme.colors.grey300};
	${p =>
		p.theme.media.large(css`
			margin-top: 10px;
			background: ${p => p.theme.colors.white};
			border-radius: ${p => p.theme.utils.borderRadius};
			padding: 25px;
			border-top: 0;
		`)};

	${p =>
		p.step === 4 &&
		p.theme.media.mediumDown(css`
			border-top: 0;
			padding-top: 0;
			margin-top: 10px;
		`)}
`;

const SubmitOrderBtn = styled.div`
	${p =>
		p.theme.media.mediumDown(css`
			${p =>
				p.step !== 4 &&
				css`
					display: none;
				`}
		`)}
`;

export default function FormSubmit() {
	const { identify } = useAnalytics();
	const {
		orderData,
		updateOrder,
		product,
		submissionError,
		setSubmissionError,
	} = useProductOrder();
	const { saveConsent } = useSaveConsent();
	const [loading, setLoading] = useState(false);
	const client = useApolloClient();

	async function submitOrder(e) {
		e.preventDefault();
		setLoading(true);
		setSubmissionError(false);

		// If payment method is invoice, then delete power object from orderData
		const order = {
			...orderData,
			...(orderData.paymentMethod === 'invoice' && {
				power: null,
				pricePerMonth: null,
			}),
		};

		try {
			const { data, error: mutationError } = await client.mutate({
				mutation: ORDER_PRODUCT,
				variables: order,
			});

			if (mutationError)
				throw new Error(
					'Order request failed: ',
					mutationError.message
				);

			// Segment identify user
			identify({
				name:
					orderData.firstname &&
					orderData.lastname &&
					`${orderData.firstname} ${orderData.lastname}`,
				email: orderData?.email,
				phone: orderData?.phone,
				address: {
					street: orderData?.address?.full,
					postalCode: orderData?.address?.zip,
					country: 'Norway',
				},
			});

			// If user gave consent to electronic marketing then run mutation
			if (orderData.acceptMarketing) {
				await saveConsent({
					event:
						orderData?.paymentMethod === 'invoice'
							? 'Produkter'
							: 'Avbetaling',
					email: orderData.email,
					phone: orderData.phone,
					action: 'Form Submitted',
				});
			}

			// Event snippet for Order Completed (Produkt) conversion page (google ads)
			if (data?.success && window?.gtag) {
				window?.gtag('event', 'conversion', {
					send_to: 'AW-832628793/QSFdCODruokYELnQg40D',
					value: orderData?.revenue || 1000,
					currency: 'NOK',
					transaction_id: data?.success, // <-- order-id
				});
			}

			// Change to last step / thank-you-page
			setLoading(false);
			updateOrder('step', 5);
		} catch (e) {
			console.log(e);
			setLoading(false);
			setSubmissionError(true);
		}
	}

	return (
		<Footer className="form-footer" step={orderData?.step}>
			<form
				onSubmit={e => {
					e.preventDefault();
					submitOrder(e);
				}}>
				<ConsentCheckbox
					id="acceptMarketing"
					name="acceptMarketing"
					defaultChecked={orderData?.acceptMarketing || false}
					onChange={e => {
						updateOrder('acceptMarketing', e.target.checked);
					}}
				/>

				<PrivacyPolicyCheckbox title="Kjøpsbetingelser og personvernerklæring">
					<>
						Ja, jeg aksepterer{' '}
						<AnchorLink
							href={orderData?.product?.terms}
							title={`Se kjøpsbetingelser for ${product?.title}`}
							target="_blank"
							rel="noreferrer">
							kjøpsbetingelsene for salg av montering av varer fra
							NTE
						</AnchorLink>
						{orderData?.paymentMethod === 'downpayment' &&
							product?.termsPowerProduct && (
								<>
									{', '}
									<AnchorLink
										href={product?.termsPowerProduct}
										title={`avtalevilkår for levering av elektrisk kraft`}
										target="_blank"
										rel="noreferrer">
										avtalevilkår for levering av elektrisk
										kraft
									</AnchorLink>
								</>
							)}
						{
							' og at NTE kan ta vare på de opplysningene jeg har lagt inn i henhold til '
						}
						<AnchorLink
							href="/om-nte/personvernerklaering"
							target="_blank"
							title="Les personvernerklæringen til NTE.no">
							NTE sin personvernerklæring
						</AnchorLink>
						.
					</>
				</PrivacyPolicyCheckbox>

				{submissionError && !loading && (
					<Error
						style={{ marginBottom: '20px' }}
						resolver="orderProduct"
						errorMessage="Product order failed"
					/>
				)}

				<SubmitOrderBtn step={orderData?.step}>
					<Button
						type="submit"
						data-cy="submit-order"
						title="Send bestilling"
						disabled={loading}
						loading={loading?.toString()}
						step={orderData?.step}>
						Send bestilling
					</Button>
				</SubmitOrderBtn>
			</form>
		</Footer>
	);
}
