import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping } from '@fortawesome/pro-regular-svg-icons/faBagShopping';

import { transparentize } from 'polished';
import { useProductOrder } from 'components/product-order/Provider';
import { priceFormatting } from 'libs/content';
import { SlideUpDown } from './Sections';
import { formatPrice } from 'components/fiber-2.0/BaseComponents';
import { Terms } from 'components/product-order/steps/Step4-Confirm';
import Heading from 'libs/heading';
import ProductItem from './ProductItem';
import UserInfo from './UserInfo';
import Discount from './Discount';
import IconAngleDown from 'images/icons/AngleDown';
import BlankButton from 'components/forms/BlankButton';

//#region Styling
const Wrap = styled.div`
	border-radius: ${p => p.theme.utils.borderRadius};
	width: 100%;
	${p =>
		p.theme.media.large(css`
			background: ${p => p.theme.colors.white};
			padding: 25px;
			${p =>
				p.$lastStep &&
				css`
					padding: 50px;
				`};
		`)};
	${p =>
		p.theme.media.mediumDown(css`
			margin-bottom: 10px;
		`)}
	.form-footer {
		display: block;
		${p =>
			p.theme.media.mediumDown(css`
				display: none;
			`)}
	}
`;

const Title = styled(Heading)`
	margin: 0 0 20px;
	color: ${p => p.theme.colors.grey900};
	${p =>
		p.theme.media.mediumDown(css`
			display: ${p => (!p.$lastStep ? 'none' : 'block')};
		`)}
`;

const Items = styled.div``;

const Item = styled.div`
	border-bottom: 1px solid ${p => p.theme.colors.grey300};
	padding-top: 7.5px;
	&:first-of-type {
		padding-top: 0;
	}
	&:last-of-type {
		padding-bottom: 7.5px;
	}
	${p =>
		p.theme.media.mediumDown(css`
			border-bottom-color: ${p => p.theme.colors.blue200};
			padding: 5px 0;
		`)}
`;

const Type = styled.p`
	color: ${p => transparentize(0.3, p.theme.colors.black)};
	font-size: 14px;
	line-height: 24px;
	text-align: left;
	margin: 0 0 5px;
`;

const Entry = styled.div`
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	${p =>
		p.$totalSum &&
		css`
			font-weight: 500;
			padding: 7.5px 0;
			margin-top: 15px;
			border-top: 1px solid ${p => p.theme.colors.grey300};
			${p =>
				p.theme.media.mediumDown(css`
					margin-top: 7.5px;
					border-top-color: ${p => p.theme.colors.blue200};
				`)}
		`};
`;

const Totals = styled.div`
	margin: 0;
	padding: 15px 0;
	${p =>
		p.theme.media.mediumDown(css`
			padding: ${p => (!p.$lastStep ? '7.5px 0' : '0')};
		`)}
	${Entry} {
		&:last-of-type {
			padding-top: 15px;
		}
	}
`;

const Label = styled.div`
	margin: 0;
`;

const Value = styled.div`
	margin: 0;
	${p =>
		p.size === 'big' &&
		css`
			color: ${p => p.theme.colors.blue600};
			font-size: 20px;
			font-weight: 600;
		`};
`;

const ToggleButton = styled(BlankButton)`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 25px !important;
	border-radius: ${p => p.theme.utils.borderRadius};
	background: ${p => p.theme.colors.white};
	text-decoration: none;
	color: ${p => p.theme.colors.grey900};
	font-weight: 500;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 20px !important;
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			padding: 15px !important;
		`)}
	${p =>
		p.$lastStep &&
		css`
			display: none;
		`};
	${p =>
		p.theme.media.large(css`
			display: none;
			visibility: hidden;
		`)}
	${p =>
		p.open &&
		css`
			border-radius: ${p => p.theme.utils.borderRadius}
				${p => p.theme.utils.borderRadius} 0 0;
		`};
	&:hover,
	&:focus {
		padding: 25px;
		background: ${p => p.theme.colors.white};
		${p =>
			p.theme.media.smallOnly(css`
				padding: 20px;
			`)}
		${p =>
			p.theme.media.XSmallOnly(css`
				padding: 15px;
			`)}
	}
`;

const ToggleLabel = styled.span`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 18px;
	line-height: 28px;
	${p =>
		p.theme.media.XSmallOnly(css`
			width: 100%;
		`)};
	${p =>
		p.theme.media.XSmallOnly(css`
			font-size: 17px;
			line-height: 27px;
		`)}
`;

const ArrowWrap = styled.span`
	margin-left: 10px;
	width: auto;
	${p =>
		p.theme.media.XSmallOnly(css`
			margin-left: auto;
			border-left: 1px solid ${p => p.theme.colors.blue200};
			padding-left: 20px;
		`)};
	svg {
		width: 15px;
		height: 20px;
		transform: ${p =>
			p.rotate === 'true' ? 'rotate(0deg)' : 'rotate(-90deg)'};
		vertical-align: middle;
		display: inline-block;
	}
`;

const CartIcon = styled.div`
	width: 20px;
	height: 25px;
	margin-right: 10px;
`;
const Price = styled.span`
	color: ${p => p.theme.colors.blue600};
	font-size: 20px;
	line-height: 30px;
	font-weight: 600;
	${p =>
		p.theme.media.XSmallOnly(css`
			display: none !important;
		`)};
`;

const Wrapper = styled.div`
	${p =>
		p.theme.media.mediumDown(css`
			background: ${p => p.theme.colors.white};
			border-radius: ${p => p.theme.utils.borderRadius};
			${p =>
				!p.$lastStep &&
				css`
					border-radius: 0 0 ${p => p.theme.utils.borderRadius}
						${p => p.theme.utils.borderRadius};
				`};
		`)}
`;

const InnerWrap = styled.div`
	${p =>
		p.$lastStep &&
		css`
			padding: 0 !important;
		`};
	${p =>
		p.theme.media.mediumDown(css`
			padding: 0 20px;
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			padding: 0 15px;
		`)}
`;

//#endregion

export default function ProductSummary({ lastStep, product }) {
	const { orderData, step, isMobile } = useProductOrder();
	const [showOrderSummary, setShowOrderSummary] = useState(!isMobile);

	useEffect(() => {
		setShowOrderSummary(!isMobile);
		//eslint-disable-next-line
	}, [isMobile, step]);

	return (
		<Wrap $lastStep={lastStep}>
			<ToggleButton
				$lastStep={lastStep}
				open={showOrderSummary}
				aria-expanded={showOrderSummary}
				aria-controls="order-summary"
				aria-label={
					showOrderSummary
						? 'Skjul ordresammendrag'
						: 'Se ordresammendrag'
				}
				onClick={() => {
					setShowOrderSummary(!showOrderSummary);
				}}>
				<ToggleLabel>
					<CartIcon>
						<FontAwesomeIcon
							icon={faBagShopping}
							size="lg"
							style={{
								verticalAlign: 'baseline',
							}}
						/>
					</CartIcon>
					{showOrderSummary
						? 'Skjul ordresammendrag'
						: 'Se ordresammendrag'}
					<ArrowWrap rotate={showOrderSummary.toString()}>
						<IconAngleDown />
					</ArrowWrap>
				</ToggleLabel>
				<Price>
					{orderData?.pricePerMonth
						? orderData?.pricePerMonth
						: formatPrice(
								orderData?.priceTotalWithDiscount ||
									orderData?.priceTotal
						  )}{' '}
					<small>{orderData?.product?.mva}</small>
				</Price>
			</ToggleButton>

			<SlideUpDown
				key="order-summary"
				id="order-summary"
				initial="open"
				visible={showOrderSummary}>
				<Wrapper
					$lastStep={lastStep}
					$show={showOrderSummary}
					aria-hidden={showOrderSummary === false}>
					<OrderInformation
						lastStep={lastStep}
						orderData={orderData}
						product={product}
					/>
				</Wrapper>
			</SlideUpDown>
		</Wrap>
	);
}

export function OrderInformation({ lastStep = false, orderData, product }) {
	const discount = orderData?.discount;

	return (
		<InnerWrap $lastStep={lastStep}>
			<Title level="h3" $lastStep={lastStep}>
				Din bestilling
			</Title>

			<Items>
				<Item>
					<Type>Produkt</Type>
					<ProductItem
						showQuantifier={!lastStep}
						lastStep={lastStep}
						product={product}
					/>
				</Item>
			</Items>

			{lastStep && !!orderData && (
				<UserInfo lastStep={true} orderData={orderData} />
			)}

			{orderData?.priceTotal && (
				<Totals lastStep={lastStep}>
					<Entry>
						<Label>Deltotal</Label>
						<Value>{priceFormatting(orderData?.priceTotal)}</Value>
					</Entry>
					{discount?.discountCalculated && (
						<DiscountInfo
							discount={discount}
							orderData={orderData}
						/>
					)}
					<Entry>
						<Label>Levering</Label>
						<Value>0 kr</Value>
					</Entry>
					<Entry $totalSum={true}>
						<Label>Totalt</Label>
						<Value size="big">
							{orderData?.pricePerMonth
								? orderData?.pricePerMonth
								: formatPrice(
										orderData?.priceTotalWithDiscount ||
											orderData?.priceTotal
								  )}{' '}
							<small>
								{!orderData?.pricePerMonth && 'kr'}{' '}
								{orderData?.product?.mva}
							</small>
						</Value>
					</Entry>
				</Totals>
			)}

			{orderData?.step === 4 && <Discount />}

			{lastStep && <Terms lastStep={true} />}
		</InnerWrap>
	);
}

function DiscountInfo({ discount, orderData }) {
	return (
		<>
			<Entry>
				<Label>
					<span>Rabatt</span>
					<small>
						({discount?.discountCode}
						{discount?.discountType === 'percentage' &&
							` - ${discount?.discount}`}
						)
					</small>
				</Label>
				<Value>- {priceFormatting(discount?.discountCalculated)}</Value>
			</Entry>
			<Entry>
				<Label>
					<span>Totalt etter rabatt</span>
				</Label>
				<Value>
					{priceFormatting(
						orderData?.priceTotal - discount?.discountCalculated
					)}
				</Value>
			</Entry>
		</>
	);
}
