import React, { useEffect, useState } from 'react';

import {
	extractAddressComponents,
	useProductOrder,
} from 'components/product-order/Provider';
import Fieldset, {
	Field,
	Column,
	Info,
	FieldTitle,
	Options,
} from '../lib/Form';
import Radio from 'components/forms/Radio';
import InputField from 'components/forms/InputField';
import Button from 'components/forms/Button';

export default function Delivery() {
	const {
		orderData,
		setOrderData,
		updateValidatedSteps,
		updateOrder,
		findDepartment,
		departments,
		trackStep,
	} = useProductOrder();

	const [differentInvoiceAddress, setDifferentInvoiceAddress] =
		useState(false);

	useEffect(() => {
		// Update the user´s department based on address-zipcode
		const department =
			orderData?.address?.zip && !!departments.length
				? findDepartment(orderData?.address?.zip, departments)
				: null;

		// Set initial delivery-address on load
		if (!differentInvoiceAddress) {
			// Update state
			setOrderData(prev => ({
				...prev,
				department,
				invoiceAddress: {
					full: orderData?.address?.full,
					street: orderData?.address?.street,
					number: orderData?.address?.number,
					zip: orderData?.address?.zip,
					city: orderData?.address?.city,
				},
			}));
		}

		// Track step to Segment
		trackStep(2);

		//eslint-disable-next-line
	}, []);

	return (
		<form
			onSubmit={async e => {
				e.preventDefault();

				// Define form const
				const form = e.target;

				// Update the user´s department based on delivery-address-zipcode
				const department =
					orderData?.address?.zip && !!departments.length
						? findDepartment(orderData?.address?.zip, departments)
						: null;

				const extractAddress = extractAddressComponents(
					orderData?.invoiceAddress?.full
				);

				setOrderData(prev => ({
					...prev,
					department,
					invoiceAddress: {
						full:
							form?.invoiceAddress_street?.value ||
							orderData?.invoiceAddress?.full,
						street: extractAddress.street,
						number: extractAddress.number,
						zip:
							form?.invoiceAddress_zip?.value ||
							orderData?.invoiceAddress?.zip,
						city:
							form?.invoiceAddress_city?.value ||
							orderData?.invoiceAddress?.city,
					},
				}));

				if (
					!orderData.deliveryMethod &&
					!orderData.address &&
					!orderData?.address?.street &&
					!orderData?.address?.zip &&
					!orderData?.address?.city
				)
					return;

				// Update validated step
				updateValidatedSteps(orderData?.step);

				// Continue to next step
				updateOrder('step', orderData?.step + 1);
			}}>
			<Fieldset legend="Leveringsmetode">
				<Field>
					<FieldTitle required>Leveringsmetode</FieldTitle>
					<Radio
						id="deliveryMethod"
						name="deliveryMethod"
						value="Elektrikeren tar med alt som trengs hjem til deg"
						label="Elektrikeren tar med alt som trengs hjem til deg"
						checked={true}
						onChange={e => {
							updateOrder('deliveryMethod', e.target.label);
						}}
						required
					/>
				</Field>
			</Fieldset>

			<Fieldset legend="Annen montering-/leveringsadresse">
				<Field>
					<FieldTitle>Annen fakturaadresse?</FieldTitle>
					<Info>
						Du har oppgitt{' '}
						{`${orderData?.address?.full}, ${orderData?.address?.zip} ${orderData?.address?.city} som din adresse. Vil du bruke en annen adresse som fakturaadresse?`}
					</Info>
					<Options placement="horizontal">
						<Radio
							key="differentInvoiceAdr"
							id="differentInvoiceAdr"
							name="differentInvoiceAddress"
							label="Ja"
							checked={differentInvoiceAddress}
							onChange={e => {
								if (e.target.checked) {
									e.target.setAttribute('checked', true);
								} else {
									e.target.removeAttribute('checked');
								}
								setDifferentInvoiceAddress(true);
							}}
						/>
						<Radio
							key="notDifferentInvoiceAdr"
							id="notDifferentInvoiceAdr"
							name="differentInvoiceAddress"
							label="Nei"
							checked={!differentInvoiceAddress}
							onChange={e => {
								if (e.target.checked) {
									e.target.setAttribute('checked', true);
								} else {
									e.target.removeAttribute('checked');
								}
								setDifferentInvoiceAddress(false);
							}}
						/>
					</Options>
				</Field>

				{differentInvoiceAddress && (
					<>
						<Field>
							<InputField
								id="invoiceAddress_street"
								name="invoiceAddress_street"
								label="Adresse"
								data-cy="invoiceAddress-street"
								defaultValue={
									orderData?.invoiceAddress?.full || ''
								}
								onChange={e =>
									updateOrder('invoiceAddress', {
										...orderData?.invoiceAddress,
										full: e.target.value,
									})
								}
								required
							/>
						</Field>

						<Field>
							<Column>
								<InputField
									id="invoiceAddress_zip"
									name="invoiceAddress_zip"
									label="Postnummer"
									pattern="\d{4}"
									defaultValue={
										orderData?.invoiceAddress?.zip || ''
									}
									data-cy="invoiceAddress-zip"
									onChange={e =>
										updateOrder('invoiceAddress', {
											...orderData?.invoiceAddress,
											zip: e.target.value,
										})
									}
									required
								/>
							</Column>
							<Column>
								<InputField
									id="invoiceAddress_city"
									name="invoiceAddress_city"
									label="Sted"
									defaultValue={
										orderData?.invoiceAddress?.city || ''
									}
									data-cy="invoiceAddress-city"
									onChange={e =>
										updateOrder('invoiceAddress', {
											...orderData?.invoiceAddress,
											city: e.target.value,
										})
									}
									required
								/>
							</Column>
						</Field>
					</>
				)}
			</Fieldset>
			<Button
				type="submit"
				title="Fortsett til betaling"
				data-cy="btn-continue-to-step-3">
				Fortsett til betaling
			</Button>
		</form>
	);
}
