import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/nb';

import { useAuth } from 'context/AuthProvider';
import {
	extractAddressComponents,
	useProductOrder,
} from 'components/product-order/Provider';
import { formatPhoneNumber, isLegalAge } from 'libs/content';
import Fieldset, { Field, Column } from '../lib/Form';
import AuthButtons from 'components/authentication/AuthButtons';
import AuthInformation from 'components/authentication/AuthInformation';
import InputField from 'components/forms/InputField';
import Button from 'components/forms/Button';
import DatePicker, { validateDateString } from 'components/forms/DatePicker';
import Loading from 'components/Loading';

dayjs.locale('nb');
dayjs.extend(customParseFormat);

const Info = styled.p`
	display: block;
	margin: 10px 0;
	color: ${p => (p.error ? p.theme.colors.coral900 : p.theme.colors.grey900)};
	${p =>
		p.theme.media.medium(css`
			margin: ${p => (p.error ? '10px 0 0' : '0 0 10px')};
		`)}
`;

export default function Step1ContactInfo() {
	const {
		product,
		productLoading,
		orderData,
		setOrderData,
		updateValidatedSteps,
		updateOrder,
		ageError,
		setAgeError,
		trackStep,
	} = useProductOrder();
	const [dateError, setDateError] = useState(false);

	const { isAuthenticated, loading: authLoading, user } = useAuth();

	// Track step to Segment
	useEffect(() => {
		trackStep(1);
		//eslint-disable-next-line
	}, []);

	// If loading then show loading-indicator
	if (authLoading || productLoading) return <Loading />;

	// If not authenticated then show login
	if (!isAuthenticated) {
		return (
			<div>
				<p>
					Velg et alternativ under for å fortsette bestillingen. For å
					vite sikkert at du er den du sier, trenger vi at du
					identifiserer deg.
				</p>
				<div>
					<AuthButtons
						title={null}
						text={null}
						redirect={`/produkter/bestill?produkt=${product?.slug}`}
					/>
					<AuthInformation
						id="product-order-popup"
						centered={false}
					/>
				</div>
			</div>
		);
	}

	// Return contact info form
	return (
		<div>
			<form
				onSubmit={async e => {
					// Prevent default behaviour
					e.preventDefault();

					// Define form const
					const form = e.target;

					const validatedDate = validateDateString(
						form?.birthdate?.value,
						'DD/MM/YYYY'
					);

					// Check if birthdate has correct format, if not update error state
					if (form?.birthdate?.value && !validatedDate) {
						setDateError(true);

						return;
					}

					if (form?.birthdate?.value && !isLegalAge(validatedDate)) {
						setAgeError(true);

						return;
					}

					// Define const with persons address
					const address = extractAddressComponents(
						form?.street?.value || orderData?.address?.full
					);
					const personAddress = {
						full: form?.street?.value || orderData?.address?.full,
						street: address?.street,
						number: address?.number,
						zip: form?.zip?.value || orderData?.address?.zip,
						city: form?.city?.value || orderData?.address?.city,
					};

					// Update user values to state
					setOrderData(prev => ({
						...prev,
						firstname:
							orderData?.firstname || form?.firstname?.value,
						lastname: orderData?.lastname || form?.lastname?.value,
						email: orderData?.email || form?.email?.value,
						phone: orderData?.phone || form?.phone?.value,
						address: personAddress,
						invoiceAddress: personAddress,
						birthdate:
							orderData?.birthdate || form?.birthdate?.value,
					}));

					// Don´t continue if is missing values
					if (
						(!orderData.firstname ||
							!orderData.lastname ||
							!orderData.email ||
							!orderData.phone ||
							!orderData.birthdate ||
							!dayjs(
								orderData.birthdate,
								'DD/MM/YYYY',
								true
							).isValid(),
						!orderData?.address ||
							!orderData?.address?.full ||
							!orderData?.address?.zip ||
							!orderData?.address?.city)
					)
						return;

					// Update validated step
					updateValidatedSteps(orderData?.step);

					// Continue to next step
					updateOrder('step', orderData?.step + 1);
				}}>
				<Fieldset legend="Kontaktinformasjon">
					{((!orderData?.stepsValidated.includes(1) &&
						!orderData?.firstname &&
						!orderData?.lastname) ||
						(isAuthenticated &&
							!user &&
							!orderData?.stepsValidated.includes(1))) && (
						<Info
							data-cy="no-phone-result"
							style={{
								marginTop: '0',
								marginBottom: '10px',
							}}>
							Vi fant ingen informasjon registrert på deg, så du
							må fylle ut din kontaktinformasjon.
						</Info>
					)}

					{isAuthenticated &&
						user &&
						!orderData?.stepsValidated.includes(1) && (
							<Info
								data-cy="make-sure-to-check-result"
								style={{
									marginTop: '0',
									marginBottom: '10px',
								}}>
								Hei {orderData?.firstname}! Sjekk at
								informasjonen er korrekt og at alle feltene er
								utfyllt.
							</Info>
						)}

					<Field>
						<Column>
							<InputField
								id="phone"
								name="phone"
								defaultValue={
									orderData?.phone &&
									formatPhoneNumber(orderData?.phone)
								}
								label="Telefon"
								type="tel"
								data-cy="product-order-phone"
								required
								onChange={e => {
									updateOrder('phone', e.target.value);
								}}
							/>
						</Column>
						<Column>
							<InputField
								id="email"
								name="email"
								label="E-post"
								type="email"
								data-cy="product-order-email"
								defaultValue={orderData?.email}
								required
								onChange={e =>
									updateOrder(e.target.name, e.target.value)
								}
							/>
						</Column>
					</Field>

					<Field>
						<Column>
							<InputField
								id="firstname"
								name="firstname"
								label="Fornavn"
								defaultValue={orderData?.firstname}
								data-cy="product-order-firstname"
								required
								onChange={e =>
									updateOrder(e.target.name, e.target.value)
								}
							/>
						</Column>
						<Column>
							<InputField
								id="lastname"
								name="lastname"
								label="Etternavn"
								defaultValue={orderData?.lastname}
								data-cy="product-order-lastname"
								onChange={e =>
									updateOrder(e.target.name, e.target.value)
								}
								required
							/>
						</Column>
					</Field>

					<Field>
						<InputField
							id="street"
							name="street"
							label="Adresse"
							defaultValue={orderData?.address?.full}
							data-cy="product-order-address-street"
							onChange={e =>
								updateOrder('address', {
									...orderData?.address,
									full: e.target.value,
								})
							}
							required
						/>
					</Field>

					<Field>
						<Column>
							<InputField
								id="zip"
								name="zip"
								label="Postnummer"
								pattern="\d{4}"
								defaultValue={orderData?.address?.zip}
								data-cy="product-order-address-zip"
								onChange={e =>
									updateOrder('address', {
										...orderData?.address,
										zip: e.target.value,
									})
								}
								required
							/>
						</Column>
						<Column>
							<InputField
								id="city"
								name="city"
								label="Sted"
								defaultValue={orderData?.address?.city}
								data-cy="product-order-address-city"
								onChange={e =>
									updateOrder('address', {
										...orderData?.address,
										city: e.target.value,
									})
								}
								required
							/>
						</Column>
					</Field>
					<Field>
						<DatePicker
							id="birthdate"
							name="birthdate"
							label="Din fødselsdato"
							helpText="Format: DD/MM/ÅÅÅÅ. Aldersgrense 18 år"
							error={
								(ageError &&
									`Du må være minimum 18 år for å kunne bestille ${product?.title}.`) ||
								(dateError &&
									'Du må skrive inn en gyldig dato med format DD/MM/ÅÅÅÅ.')
							}
							defaultValue={
								orderData?.birthdate &&
								dayjs(
									orderData?.birthdate,
									'DD/MM/YYYY'
								).format('DD/MM/YYYY')
							}
							maxDate={dayjs().subtract(18, 'year').toDate()}
							dataCy="product-order-birthdate"
							onChange={value => {
								if (!value) return;
								setAgeError(false);
								setDateError(false);

								const validatedDate = validateDateString(value);

								if (!isLegalAge(validatedDate)) {
									setAgeError(true);
									return;
								}

								if (!validatedDate) {
									setDateError(true);
									return;
								}

								updateOrder('birthdate', validatedDate);
							}}
							required
						/>
					</Field>
				</Fieldset>
				<Button
					type="submit"
					title="Fortsett til levering"
					data-cy="btn-continue-to-step-2"
					disabled={!!ageError || !!dateError}>
					Fortsett til levering
				</Button>
			</form>
		</div>
	);
}
