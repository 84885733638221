import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Box from 'components/forms/Box';
import Button from 'components/forms/Button';
import InputField from 'components/forms/InputField';
import Radio from 'components/forms/Radio';
import PowerProductSelect from 'components/product-order/lib/PowerProductSelect';
import { useProductOrder } from 'components/product-order/Provider';
import MeterInfoPopup from 'components/strombestilling/components/MeterInfoPopup';
import { SuccessText } from 'components/strombestilling/pages/orderSuccess';
import Fieldset, { Field, FieldTitle, Info, Options } from '../lib/Form';

const BoxWrap = styled(Box)`
	.sub-header {
		margin: 20px 0;
	}
	.illustration {
		max-height: 150px;
		float: right;
		${p =>
			p.theme.media.mediumDown(css`
				max-height: 100px;
			`)};
	}
`;

export default function Payment() {
	const {
		orderData,
		setOrderData,
		updateValidatedSteps,
		product,
		trackStep,
		storedPowerOrder,
	} = useProductOrder();

	const isDownPayment =
		orderData?.paymentMethod === 'downpayment' &&
		product?.paymentMethods?.includes('Avbetaling (3 år)');

	const [meterIDError, setMeterIDError] = useState('');

	// Set initial payment-method, power-product and downpayment-months on load
	useEffect(() => {
		if (
			orderData?.paymentMethod === 'downpayment' &&
			!orderData?.power?.product
		) {
			setOrderData(prev => ({
				...prev,
				paymentMethod: orderData?.paymentMethod,
				power: {
					product: 'Jeg har allerede strømavtale fra NTE',
					meterIds: '',
					downpaymentMonths: '3 år (36 måneder)',
				},
			}));
		} else if (orderData?.paymentMethod === 'invoice') {
			setOrderData(current => {
				// remove power, pricePerMonth and downpaymentMonths key from object
				const { power, pricePerMonth, ...rest } = current;
				return rest;
			});
		}

		//eslint-disable-next-line
	}, [orderData?.paymentMethod]);

	// Track step to Segment
	useEffect(() => {
		trackStep(3);
		//eslint-disable-next-line
	}, []);

	// If user has a stored power order, return
	if (
		storedPowerOrder?.isElektroOrder &&
		storedPowerOrder?.orderId &&
		orderData?.power?.product === 'Spotpris'
	) {
		return (
			<form
				onSubmit={async e => {
					e.preventDefault();

					if (!storedPowerOrder?.meterIds) return;

					// Update validated step
					updateValidatedSteps(orderData?.step);

					// Continue to next step
					setOrderData(prev => ({
						...prev,
						step: orderData?.step + 1,
						power: {
							...prev.power,
							orderID: storedPowerOrder?.orderId,
							meterIds: storedPowerOrder?.meterIds,
						},
					}));
				}}>
				<BoxWrap>
					<h3>
						Takk for din bestilling av Spotpris, du kan nå fullføre
						bestillingen med avbetaling.
					</h3>
					<SuccessText startDate={storedPowerOrder?.startDate} />
				</BoxWrap>

				<Button
					type="submit"
					disabled={!storedPowerOrder?.orderId}
					title="Fortsett til bekreftelse"
					data-cy="btn-continue-to-step-4"
					style={{ marginTop: '30px' }}>
					Fortsett bestilling av {orderData?.product?.internalTitle}
				</Button>
			</form>
		);
	}

	return (
		<form
			onSubmit={async e => {
				e.preventDefault();

				setMeterIDError('');

				// Check for downpayment and missing meterIds or orderId
				const isDownpayment = orderData.paymentMethod === 'downpayment';

				// Don't continue if user is missing payment-method or power product
				if (
					!orderData?.paymentMethod ||
					(!orderData?.power?.product && isDownpayment)
				)
					return;

				const isSpotpris = orderData?.power?.product === 'Spotpris';
				const isExistingCustomer =
					orderData?.power?.product ===
					'Jeg har allerede strømavtale fra NTE';
				const missingMeterId = !orderData?.power?.meterIds;
				const missingOrderId = !storedPowerOrder?.orderId;

				// If downpayment, spotpris and missing meterIds or orderId, return
				if (
					isDownpayment &&
					isSpotpris &&
					(missingMeterId || missingOrderId)
				) {
					return;
				}

				// If downpayment, existing customer and missing meterIds, return
				if (isDownpayment && isExistingCustomer && missingMeterId) {
					return;
				}

				// Validate meterID length

				if (
					isDownPayment &&
					e?.target?.meterIds?.value?.length !== 8 &&
					e?.target?.meterIds?.value?.length !== 18
				) {
					setMeterIDError(
						'Målepunkt-ID består av 18 siffer, de ti første er alltid 7070575000, og de resterende 8 er ditt unike nummer.'
					);
					return;
				}

				// Update validated step
				updateValidatedSteps(orderData?.step);

				// Update orderData with stored power order if necessary
				setOrderData(prev => ({
					...prev,
					step: isDownpayment && isSpotpris ? 3 : orderData?.step + 1,
					power: {
						...prev.power,
						meterIds: orderData?.power?.meterIds,
						orderID:
							isDownpayment && isSpotpris
								? storedPowerOrder?.orderId
								: prev.power?.orderID,
					},
				}));
			}}>
			<Fieldset legend="Betalingsmetode">
				<Field>
					<FieldTitle required>Betalingsmetode</FieldTitle>
					<Options>
						{product?.paymentMethods?.includes('Faktura') && (
							<Radio
								key="invoice"
								id="invoice"
								label="Faktura"
								helpText={`Faktura vil bli tilsendt ${orderData?.email} etter at bestillingen er ferdigbehandlet.`}
								data-cy="paymentMethod"
								name="paymentMethod"
								required
								checked={orderData?.paymentMethod === 'invoice'}
								onChange={e => {
									if (e.target.checked) {
										e.target.setAttribute('checked', true);
									} else {
										e.target.removeAttribute('checked');
									}

									// Remove power, pricePerMonth key from object and set paymentMethod to invoice
									setOrderData(prev => {
										const newState = {
											...prev,
											paymentMethod: 'invoice',
										};
										delete newState.power;
										delete newState.pricePerMonth;
										return newState;
									});
								}}
							/>
						)}
						{product?.paymentMethods?.includes(
							'Avbetaling (3 år)'
						) && (
							<Radio
								key="downpayment"
								id="downpayment"
								label="Avbetaling (3 år - 36 mnd)"
								helpText="Krever at du er strømkunde av NTE, månedlig beløp vil bli fakturert på din strømfaktura."
								data-cy="paymentMethod"
								name="paymentMethod"
								required
								checked={
									orderData?.paymentMethod === 'downpayment'
								}
								onChange={e => {
									if (e.target.checked) {
										e.target.setAttribute('checked', true);
									} else {
										e.target.removeAttribute('checked');
									}
									setOrderData(prev => ({
										...prev,
										paymentMethod: 'downpayment',
										power: {
											...prev.power,
											downpaymentMonths:
												'3 år (36 måneder)',
										},
									}));
								}}
							/>
						)}
					</Options>
				</Field>
			</Fieldset>

			{isDownPayment && (
				<>
					<PowerProductSelect />

					{orderData?.power?.product ===
						'Jeg har allerede strømavtale fra NTE' && (
						<Field style={{ marginTop: '10px' }}>
							<FieldTitle required>Målepunkt-ID</FieldTitle>

							<Info>
								Alle strømmålere i Norge har et unikt
								identifikasjonsnummer som kalles målepunkt-ID.
								Målepunkt-ID består av 18 siffer.{' '}
								<MeterInfoPopup
									customTitle="Hvordan finner jeg målepunkt-ID?"
									meterPointIdOnly={true}
									buttonStyle={{ marginBottom: '0' }}
								/>
							</Info>

							<InputField
								id="meterIds"
								name="meterIds"
								label="Målepunkt-ID"
								defaultValue={orderData?.power?.meterIds}
								data-cy="meterIds"
								error={
									meterIDError?.length > 0 ? meterIDError : ''
								}
								onChange={e => {
									setMeterIDError('');
									setOrderData(prev => ({
										...prev,
										power: {
											...prev.power,
											meterIds: e.target.value,
										},
									}));
								}}
								required
							/>
						</Field>
					)}
				</>
			)}

			<Button
				type="submit"
				disabled={
					(isDownPayment && !orderData?.power?.meterIds) ||
					(isDownPayment &&
						orderData?.power?.product === 'Spotpris' &&
						!storedPowerOrder?.orderId) ||
					(!isDownPayment && !orderData?.paymentMethod) ||
					(isDownPayment && meterIDError)
				}
				title="Fortsett til bekreftelse"
				data-cy="btn-continue-to-step-4">
				Fortsett til bekreftelse
			</Button>
		</form>
	);
}
