import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { gql, useLazyQuery } from '@apollo/client';

import { useProductOrder } from 'components/product-order/Provider';
import Fieldset, { Info } from './Form';
import InputAndButtonWrap from 'components/forms/InputAndButtonWrap';
import InputField from 'components/forms/InputField';
import Button from 'components/forms/Button';
import IconSuccess from 'images/icons/Success';

const DiscountWrap = styled.div`
	> div:has(> div.error) {
		margin-bottom: 27px;
	}
	${p =>
		p.theme.media.mediumDown(css`
			margin-bottom: 20px;
		`)}
	p {
		margin: 0 0 10px;
	}
`;

const Status = styled.div`
	margin-bottom: 10px;
	p > svg {
		width: 17px;
		height: 20px;
		vertical-align: sub;
	}
`;

export default function Discount() {
	const {
		orderData,
		setOrderData,
		discountError,
		setDiscountError,
		product,
	} = useProductOrder();
	const [enteredDiscountCode, setEnteredDiscountCode] = useState('');

	const [
		validateDiscountCode,
		{
			data: validateDiscountCodeData,
			loading: validateDiscountCodeLoading,
			error: validateDiscountCodeError,
		},
	] = useLazyQuery(
		gql`
			query validateDiscountCode(
				$contentfulId: String!
				$discountCode: String!
			) {
				validateDiscountCode(
					contentfulId: $contentfulId
					discountCode: $discountCode
				) {
					orderId
					discount
					discountType
					discountCode
					discountCalculated
				}
			}
		`,
		{
			variables: {
				contentfulId: product?.contentful_id,
				discountCode: enteredDiscountCode,
			},
			fetchPolicy: 'network-only',
		}
	);

	async function runCodeValidation() {
		if (!enteredDiscountCode) return;

		// Remove discount-object from order-data-state
		const newOrderData = { ...orderData };
		delete newOrderData.discount;
		setOrderData(newOrderData);

		// Run query
		const validateCodeResponse = await validateDiscountCode({
			variables: {
				contentfulId: product?.contentful_id,
				discountCode: enteredDiscountCode,
			},
		});

		const validatedCode = validateCodeResponse?.data?.validateDiscountCode;

		// Add order-id to order-data
		if (validatedCode?.orderId)
			setOrderData(prev => ({
				...prev,
				orderId: validatedCode.orderId || undefined,
			}));

		if (!validateDiscountCodeLoading && validateDiscountCodeError) {
			setDiscountError(true);
			return;
		}

		// If either calculated discount, percentage or calculation exists then return early
		if (
			!validatedCode?.discount ||
			!validatedCode?.discountCode ||
			!validatedCode?.discountCalculated
		) {
			setDiscountError(true);
			return;
		}

		// Add discount-data to order-data
		setOrderData(prev => ({
			...prev,
			orderId: validatedCode.orderId || undefined,
			discount: {
				discount: validatedCode?.discount,
				discountType: validatedCode?.discountType,
				discountCode: validatedCode?.discountCode,
				discountCalculated: validatedCode?.discountCalculated,
			},
		}));
		setDiscountError(false);

		return;
	}

	return (
		<form
			onSubmit={e => {
				// Prevent default behaviour
				e.preventDefault();
				runCodeValidation();
			}}>
			<Fieldset legend="Rabattkode">
				{validateDiscountCodeData?.validateDiscountCode &&
					orderData?.discount?.discount && (
						<Status>
							<Info data-cy="discountCode-success">
								<IconSuccess
									title="Ikon som indikerer at rabattkoden er gyldig og rabatten ble lagt til"
									style={{ color: '#5cb16b' }}
								/>
								<span>
									Rabatt på{' '}
									{orderData?.discount?.discountType === 'sum'
										? `${orderData?.discount?.discount} kr`
										: orderData?.discount?.discount}{' '}
									ble lagt til.
								</span>
							</Info>
						</Status>
					)}

				{!validateDiscountCodeData?.validateDiscountCode?.discount &&
					!orderData?.discount?.discount && (
						<>
							<DiscountWrap>
								<InputAndButtonWrap>
									<InputField
										id="discountCode"
										name="discountCode"
										label="Har du en rabattkode?"
										type="text"
										data-cy="discountCode"
										loading={validateDiscountCodeLoading}
										error={
											((discountError ||
												(!validateDiscountCodeLoading &&
													validateDiscountCodeError)) &&
												'Dette er ikke en gyldig rabattkode') ||
											false
										}
										defaultValue={
											enteredDiscountCode ||
											orderData?.discount?.discountCode ||
											''
										}
										onChange={e =>
											setEnteredDiscountCode(
												e.target.value
											)
										}
									/>
									<Button
										type="submit"
										disabled={validateDiscountCodeLoading}
										data-cy="discountCode-check-btn"
										title="Klikk for å sjekke om rabattkoden fungerer">
										Sjekk
									</Button>
								</InputAndButtonWrap>
							</DiscountWrap>
						</>
					)}
			</Fieldset>
		</form>
	);
}
