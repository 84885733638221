import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';

import { useProductOrder } from 'components/product-order/Provider';
import { priceFormatting } from 'libs/content';
import LazyImage from 'components/LazyImage';
import QtySelect from 'components/forms/QtySelect';

const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-bottom: 20px;
`;

const ImgWrapperStyle = () => css`
	width: 120px;
	min-height: 120px;
	border: 1px solid ${p => p.theme.colors.grey300};
	border-radius: ${p => p.theme.utils.borderRadius};
	background: ${p => p.theme.colors.grey200};
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
`;

const ImageWrapper = styled.div`
	${ImgWrapperStyle}
	img {
		max-height: 100%;
		margin: 0 auto;
		display: block;
	}
`;

const SvgWrapper = styled.div`
	${ImgWrapperStyle}
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	svg path {
		fill: ${p => p.theme.colors.grey400};
	}
`;

const InfoWrapper = styled.div`
	width: calc(100% - 120px);
	padding: 0 0 0 20px;
	text-align: left;

	.qty-select {
		margin-top: 10px;
	}
`;

const Name = styled.p`
	font-size: 17px;
	line-height: 27px;
	margin: 0 0 5px;
	small {
		display: block;
	}
`;

const Qty = styled.p`
	font-size: 17px;
	line-height: 29px;
	margin: 0;
`;

const Price = styled.p`
	font-size: 17px;
	line-height: 29px;
	font-weight: 500;
	margin: 0;
`;

const PriceCompare = styled.div`
	font-size: 17px;
	line-height: 29px;
	font-weight: 500;
	margin: 0;
	@media (max-width: 420px) {
		span {
			display: block;
			margin: 0;
		}
	}
`;

const OldPrice = styled.span`
	text-decoration: line-through;
	margin-right: 7.5px;
	color: ${p => p.theme.colors.grey700};
	font-weight: 400;
`;

const CampaignPrice = styled.span`
	margin-left: 7.5px;
	color: ${p => p.theme.colors.blue600};
	font-weight: 600;
`;

export default function ProductItem({
	product,
	showQuantifier = true,
	lastStep,
}) {
	const { orderData, setOrderData } = useProductOrder();

	if (!product) return null;

	return (
		<Wrapper>
			{product?.image?.file?.url ? (
				<ImageWrapper>
					<LazyImage
						{...product?.image}
						width={120}
						height={120}
						fit="pad"
						alt={product?.smallTitle || product?.title}
					/>
				</ImageWrapper>
			) : (
				<SvgWrapper>
					<FontAwesomeIcon
						size="5x"
						icon={faImage}
						title={product?.smallTitle || product?.title}
					/>
				</SvgWrapper>
			)}

			<InfoWrapper>
				<Name>
					<span>{product?.smallTitle || product?.title}</span>
				</Name>
				{lastStep && (
					<Qty>Antall: {orderData?.product?.quantity} stk</Qty>
				)}

				{product?.campaignPrice ? (
					<PriceCompare>
						<OldPrice>{priceFormatting(product?.price)}</OldPrice>
						<CampaignPrice>
							{priceFormatting(product?.campaignPrice)}
						</CampaignPrice>
					</PriceCompare>
				) : (
					<Price>{priceFormatting(product?.price)}</Price>
				)}

				{showQuantifier && (
					<QtySelect
						min={1}
						max={5}
						className="qty-select"
						quantity={orderData?.product?.quantity}
						setQuantity={qty => {
							setOrderData(prev => ({
								...prev,
								product: { ...prev?.product, quantity: qty },
							}));
						}}
					/>
				)}
			</InfoWrapper>
		</Wrapper>
	);
}
