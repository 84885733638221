import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { transparentize } from 'polished';
import { motion, AnimatePresence } from 'framer-motion';

import { useProductOrder } from '../Provider';

//#region Styling
const List = styled.div`
	margin: 0 auto;
	font-size: 18px;
	min-height: 50px;
	width: 100%;
`;

const ItemHeader = styled.button`
	position: relative;
	width: 100%;
	border: 0;
	text-align: left;
	padding: 0;
	border-radius: ${p => p.theme.utils.borderRadius}
		${p => p.theme.utils.borderRadius} 0 0;
	overflow: hidden;
	display: block;
	cursor: ${p => (p.$active ? 'inherit' : 'pointer')};
	background: transparent;
	&:focus {
		outline: 0;
	}
	${p =>
		p.$active &&
		css`
			background: ${p => p.theme.colors.white};
		`}
`;

const Item = styled.div`
	background: white;
	border-radius: ${p => p.theme.utils.borderRadius};
	width: 100%;
	margin-bottom: 10px;
	position: relative;
	z-index: ${p => (p.$active ? '2' : '1')};
	scroll-margin: 50px;
	${p =>
		p.theme.media.medium(css`
			scroll-margin: 100px;
		`)}

	${p =>
		p.disabled &&
		css`
			background: ${p => transparentize('0.7', p.theme.colors.white)};
			${ItemHeader} {
				color: white;
				cursor: no-drop;
			}
		`}
`;

const Title = styled.div`
	margin: 0;
	padding: 25px 45px 25px 25px;
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
	min-height: 50px;
	vertical-align: middle;
	align-items: center;
	display: flex;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 20px 45px 20px 20px;
			font-size: 18px;
			line-height: 28px;
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			padding: 15px 45px 15px 20px;
			font-size: 17px;
			line-height: 27px;
		`)}
	svg {
		top: 50%;
		transform: translateY(-50%);
		left: 10px;
	}
`;

const InnerContent = styled.div`
	padding: 0 25px 35px 25px;
	font-size: 17px;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 0 20px 30px 20px;
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			padding: 0 15px 25px 15px;
		`)}
	form {
		> button[type='submit'] {
			margin-left: auto;
			display: block;
		}
	}
`;

const Content = styled.div`
	font-size: inherit;
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const Pencil = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	width: 80px;
	height: 80px;
	text-align: center;
	line-height: 53px;
	margin: 0px;
	background-position: center center;
	transition: all 0.45s ease-out;
	padding: 17px 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	${p =>
		p.theme.media.smallOnly(css`
			width: 68px;
			height: 68px;
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			width: 57px;
			height: 57px;
		`)}
	&:hover {
		background: ${p => transparentize('0.8', p.theme.colors.blue600)};
		svg {
			fill: ${p => p.theme.colors.blue600};
		}
	}
	svg {
		width: 22px;
		height: 20px;
		transition: fill 0.45s ease-out;
		fill: ${p => p.theme.colors.black};
		${p =>
			p.theme.media.smallOnly(css`
				width: 20px;
				height: 18px;
			`)}
	}
`;

//#endregion

function SectionItem({ section }) {
	const { orderData, updateOrder } = useProductOrder();

	const stepIsDisabled =
		section?.step !== 1 &&
		!orderData?.stepsValidated?.includes(section?.step - 1);
	const isOpen = orderData?.step === section?.step;

	return (
		<Item
			key={section.id}
			id={section.id}
			data-step={'section-' + section.step}
			data-cy={`product-order-step-${section.step}`}
			$active={orderData?.sstep === section.step}
			style={{
				overflow: (section?.step === 1 && 'visible') || 'hidden',
				zIndex: (orderData?.step === section.step && 2) || 1,
			}}
			{...(stepIsDisabled && {
				disabled: true,
			})}>
			<ItemHeader
				$active={orderData?.step === section.step}
				aria-expanded={orderData?.sstep === section.step}
				aria-controls={`content-for-${section.id}`}
				id={`button-for-${section.id}`}
				disabled={stepIsDisabled || false}
				aria-label={
					orderData?.step !== section.step
						? `Klikk for å åpne steg ${section.step}`
						: `Steg ${section.step}`
				}
				data-cy={`step-header-btn-for-${section.id}`}
				onClick={() => {
					updateOrder('step', section?.step);
				}}>
				<Title>
					<span>{section.title}</span>
				</Title>
				{!stepIsDisabled && orderData?.step !== section.step && (
					<Pencil aria-label="Klikk for å endre informasjon">
						<FontAwesomeIcon icon={faPencil} />
					</Pencil>
				)}
			</ItemHeader>

			<SlideUpDown
				key={`step-content-for-${section.id}`}
				data-cy={`step-content-for-${section.id}`}
				visible={isOpen && !stepIsDisabled}
				role="tabpanel"
				id={'content-for-' + section.id}
				aria-labelledby={'button-for-' + section.id}>
				<Content>
					<InnerContent
						key={`inner-content-${section.id}`}
						id={`inner-content-${section.id}`}>
						{section?.content}
					</InnerContent>
				</Content>
			</SlideUpDown>
		</Item>
	);
}

export default function Sections({ tabs = [], ariaLabel = '' }) {
	return (
		<List role="tablist" aria-label={ariaLabel} id="order-form-sections">
			{!!tabs.length &&
				tabs.map((tab, i) => {
					i++;
					return <SectionItem key={i} role="tab" section={tab} />;
				})}
		</List>
	);
}

export function SlideUpDown({
	visible = false,
	id = '',
	variants = {
		open: {
			opacity: 1,
			height: 'auto',
			transition: { duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] },
		},
		collapsed: {
			opacity: 0,
			height: 0,
			transition: { duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] },
		},
	},
	initial = 'collapsed',
	children,
}) {
	return (
		<AnimatePresence initial={false}>
			{visible && (
				<motion.div
					key={id}
					id={id}
					initial={initial}
					animate="open"
					exit="collapsed"
					variants={variants}
					transition={{
						duration: 0.5,
					}}>
					<div>{children}</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}
