import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import { fadeIn } from 'libs/animations';
import { useProductOrder } from './Provider';
import Sections from './lib/Sections';
import MaxWidth from 'layouts/max-width';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import ProductSummary from './lib/ProductSummary';
import Step1ContactInfo from './steps/Step1-ContactInfo';
import Step2Delivery from './steps/Step2-Delivery';
import Step3Payment from './steps/Step3-Payment';
import Step4Confirm from './steps/Step4-Confirm';
import Step5ThankYou from './steps/Step5-ThankYou';
import FormSubmit from './steps/FormSubmit';
import AuthLogOut from 'components/authentication/AuthLogOut';
import Spacing from 'layouts/Spacing';

const Intro = styled(TitleAndText)`
	.max-width {
		padding-left: 0;
		padding-right: 0;
	}
	h1,
	h2,
	p {
		color: white !important;
	}
	p:last-of-type {
		margin-bottom: 0;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	min-height: 30vh;
	opacity: 0;
	animation-delay: 500ms;
	animation-name: ${fadeIn};
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
`;

const Left = styled.div`
	width: 100%;
	${p =>
		p.theme.media.large(css`
			padding: 0 20px 0 0;
			width: calc(100% - 440px);
		`)}
`;

const Right = styled.div`
	width: 100%;
	padding: 0 0 20px;
	${p =>
		p.theme.media.large(css`
			width: 440px;
			padding: 0 0 0 20px;
		`)}
`;

const Inner = styled.div`
	opacity: 0;
	animation-delay: 500ms;
	animation-name: ${fadeIn};
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
`;

export default function FrontPage() {
	const { isMobile, product, orderData, clearStorage } = useProductOrder();

	// Show Thank-you / receipt-page if is on last step
	if (orderData?.step === 5) return <Step5ThankYou />;

	return (
		<>
			<section id="order-form-intro">
				<Intro
					title={`Bestill ${product?.smallTitle || product?.title}`}
					text="Gå gjennom stegene under for å fullføre bestillingen"
					headinglevel="h1"
					isfirstheading={true}
					spacing={{ top: 'large', bottom: 'medium' }}
				/>
			</section>

			<section>
				<MaxWidth>
					<Spacing>
						<Wrapper>
							{isMobile && <ProductSummary product={product} />}
							<Left>
								<Inner>
									<Sections
										ariaLabel={`Bestillingsskjema for ${
											product?.smallTitle ||
											product?.title
										} fra NTE`}
										tabs={[
											{
												step: 1,
												id: 'contactinfo',
												title: '1. Hvem er du?',
												content: <Step1ContactInfo />,
											},
											{
												step: 2,
												id: 'delivery',
												title: '2. Hvor vil du at produktet skal leveres?',
												content: <Step2Delivery />,
											},
											{
												step: 3,
												id: 'payment',
												title: '3. Hvordan vil du betale?',
												content: <Step3Payment />,
											},
											{
												step: 4,
												id: 'confirm',
												title: '4. Bekreft din bestilling',
												content: <Step4Confirm />,
											},
										]}
									/>

									<AuthLogOut
										textcolor="white"
										onClick={() => {
											clearStorage();

											// Redirect to main page
											navigate('/elektriker');
										}}
									/>
								</Inner>
							</Left>
							{!isMobile && (
								<Right>
									<Inner>
										<ProductSummary product={product} />
										{orderData?.step === 4 &&
											orderData?.stepsValidated.includes(
												3
											) && <FormSubmit />}
									</Inner>
								</Right>
							)}
						</Wrapper>
					</Spacing>
				</MaxWidth>
			</section>
		</>
	);
}
